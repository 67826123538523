.reaction_container {
    text-align: center;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
}

.reaction_content {
    min-width: 400px;
    width: 70%;
    margin: 0 auto;
    position: relative;
}


.right_button {
    position: absolute !important;
    right: 0%;
}

.stage_info {
    position: absolute !important;
    right: 50%;
    transform:translateX(+50%);
}

.reaction_content {line-height: 1.4; line-break: auto;}
sub { vertical-align: bottom; position: relative; top: 0.3em; }

@media (max-width: 1900px) {
    .reaction_content {
        font-size: 12px;
    }
}

@media (max-width: 1600px) {
    .reaction_content {
        font-size: 11px;
    }
}

@media (max-width: 1300px) {
    .reaction_content {
        font-size: 10px;
    }
}

@media (max-width: 1000px) {
    .reaction_content {
        font-size: 9px;
    }
}

@media (max-width: 700px) {
    .reaction_content {
        font-size: 8px;
    }
}


@media (max-width: 400px) {
    .reaction_content {
        font-size: 7px;
    }
}

.reaction_content {
    margin-top: 40px;
}
