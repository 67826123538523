iframe {
  display: none;
}

::-moz-selection { /* Code for Firefox */
  color: white;
  background: #03a8c0;
}

::selection {
  color: white;
  background: #03a8c0;
}

html, body, #app, #app>div {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  min-width: 400px;
  caret-color: transparent;
}

canvas {
  margin: 0;
  z-index: 999;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader {
  border: 8px solid lightgray; /* Light grey */
  border-top: 8px solid black; /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
  position: absolute;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (min-width: 1900px) {
  h2 {
    font-size: 22px;
  }
  h3, input {
    font-size: 20px !important;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
}


@media (max-width: 1900px) {
  h2 {
    font-size: 20px;
  }
  h3, input {
    font-size: 18px !important;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  h2 {
    font-size: 18px;
  }
  h3, input {
    font-size: 16px !important;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 12px;
  }
}

@media (max-width: 1300px) {
  h2 {
    font-size: 16px;
  }
  h3, input {
    font-size: 14px !important;
  }
  h4 {
    font-size: 12px;
  }
  h5 {
    font-size: 10px;
  }
}

@media (max-width: 1000px) {
  h2 {
    font-size: 14px;
  }
  h3, input {
    font-size: 12px !important;
  }
  h4 {
    font-size: 10px;
  }
  h5 {
    font-size: 8px;
  }

}

@media (max-width: 700px) {

  h2 {
    font-size: 12px;
  }
  h3 {
    font-size: 10px;
  }
  h4 {
    font-size: 8px;
  }
  h5 {
    font-size: 6px;
  }
}


@media (max-width: 500px) {
  h2 {
    font-size: 12px;
  }
  h3 {
    font-size: 10px;
  }
  h4 {
    font-size: 8px;
  }
  h5 {
    font-size: 6px;
  }
}

h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
}

.info_icon {
  background-color: transparent;
  color: black;
  cursor: pointer;
}

.info_icon:hover {
  color: #03a8c0;
}

.molecule_board, .molecule_board_3D {
  overflow: hidden;
  position: fixed;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 15px;
  z-index: 10005 !important;
  display: flex;
  flex-direction: row;
  /*bottom: 1.5vh;
  right: 1.5vh;*/
  top: 0.1vh;
  left: 50%;
  transform: translateX(-50%);
}

.molecule_board_3D {
  z-index: 10004 !important;
}

.molecule_board_icon {
  padding: 0.2vh 1vw 0.2vh 1vw;
  display: inline-block;
  background-color: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  color: black;
}

.molecule_board_icon_left {
  border-right: 1px solid lightgray !important;
}

#mol3D canvas {
  position: relative;
  z-index: 10002 !important;
}

.molecule_board_icon {
  color: black;
}

.molecule_board_icon_used {
  color: #03a8c0;
}

.link {
  text-decoration: underline;
  color: #03a8c0;
}

@media (hover: hover) {
  .molecule_board_icon:hover {
    background-color: rgba(3, 168, 192, 0.2);
  }

  .molecule_board_icon_used:hover {
    color: black;
  }
}

a {
  color: #03a8c0;
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
  border-radius: 20px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #03a8c0;
  background-image: -webkit-linear-gradient(45deg,
  rgba(255, 255, 255, .2) 25%,
  transparent 25%,
  transparent 50%,
  rgba(255, 255, 255, .2) 50%,
  rgba(255, 255, 255, .2) 75%,
  transparent 75%,
  transparent)
}

.rating {
  color: #03a8c0;
  font-weight:bold
}

.icon-button {
  margin-right: 1% !important;
}

.icon-button-active {
  background-color: rgba(3, 168, 192, 0.3) !important;
}

.icon-button:hover {
  background-color: rgba(3, 168, 192, 0.2) !important;
}

input {
    caret-color: #03a8c0;
}